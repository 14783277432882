import { faFacebook, faInstagram, faTelegram, faTiktok, faWhatsapp, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

function Footer(props) {
  const [Web,setWeb] = useState();
  const [Footer,setFooter] = useState();
  const urlClick = () => {
    window.scrollTo(0, 0);
  }
  useEffect(() => {
    if(props?.Web?.data){
        var data = props?.Web?.data
        setWeb(data)
        if(data?.footer){
            setFooter(JSON.parse(data.footer?.value))
        }
    }
  }, [props?.Web?.data]);
  return (
    <div>
      <div className="amr-footer mt-3">
        <div className="row">
            <div className="col-lg-6 mb-5">
                <div className="form-img-footer">
                    <img src={Footer?.img}></img>
                    <div className="keterangan">
                        {Footer?.text}
                    </div>
                </div>
            </div>
            <div className="col-lg-6">
                <div className="form-other">
                    <div className="row">
                        <div className="col-6 col-lg-4 mb-4">
                            <div className="induk">
                                <span className="judul">PETA SITUS</span>
                                <Link to="/" onClick={() => urlClick()}> <span>Beranda</span></Link>
                                <Link to="/game-search" onClick={() => urlClick()}> <span>Semua Game</span></Link>
                                <Link to="/login" onClick={() => urlClick()}> <span>Masuk</span></Link>
                                <Link to="/register" onClick={() => urlClick()}><span>Daftar</span></Link>
                            </div>
                        </div>
                        <div className="col-6 col-lg-4 mb-4">
                            <div className="induk">
                                <span className="judul">TOPUP LAIN NYA</span>
                            </div>
                        </div>
                        <div className="col-12 col-lg-4">
                            <div className="induk">
                                <span className="judul">IKUTI KAMI</span>
                            </div>
                            <div className='for-ico mt-2'>
                                {Footer?.kontak?.fb == undefined ? "" : (
                                    <a target="_blank" href={Footer?.kontak?.fb}>
                                        <FontAwesomeIcon icon={faFacebook}/>
                                    </a>  
                                )}
                                {Footer?.kontak?.ig == undefined ? "" : (
                                    <a target="_blank" href={Footer?.kontak?.ig}>
                                        <FontAwesomeIcon icon={faInstagram}/>
                                    </a>  
                                )}
                                {Footer?.kontak?.tt == undefined ? "" : (
                                    <a target="_blank" href={Footer?.kontak?.tt}>
                                        <FontAwesomeIcon icon={faTiktok}/>
                                    </a>  
                                )}
                                {Footer?.kontak?.tele == undefined ? "" : (
                                    <a target="_blank" href={Footer?.kontak?.tele}>
                                        <FontAwesomeIcon icon={faTelegram}/>
                                    </a>  
                                )}
                                {Footer?.kontak?.wa == undefined ? "" : (
                                    <a target="_blank" href={Footer?.kontak?.wa}>
                                        <FontAwesomeIcon icon={faWhatsapp}/>
                                    </a>  
                                )}
                                {Footer?.kontak?.yt == undefined ? "" : (
                                    <a target="_blank" href={Footer?.kontak?.yt}>
                                        <FontAwesomeIcon icon={faYoutube}/>
                                    </a>  
                                )}
                            </div>
                            <div className='amr-font-3 mt-4' style={{wordBreak: "break-word"}}>
                                {Footer?.alamat}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-lg-12">
                <hr/>
            </div>
        </div>
        
    </div>
    </div>
  )
}

export default Footer