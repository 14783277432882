import { faCrown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useRef, useState } from 'react'
import { Alert, Modal } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import Skeleton from 'react-loading-skeleton';
import { useNavigate } from 'react-router-dom';
import { Func } from '../../glob/Func';
import Notify from '../../glob/Notify';
import "./Style/Membership.css";
function Membership() {
    const [core,setCore] = useState({"loading" : true});
    const [dataLevel,setDataLevel] = useState(0);
    const [SelectLevel,setSelectLevel] = useState();
    const CardPay = useRef();
    const [payment,setPayment] = useState({loading : false});
    const [SelectedPayment,SetSelectPay] = useState({});
    const [ViewCatPay,setCatPay] = useState({});
    const [modalKonfirmasi,setModalKonf] = useState({"show" : false});
    const [LoadingPost,setLoadingPost] = useState(false);
    const [user,setUser] = useState();
    const navigate = useNavigate();
    useEffect(() => {
      var timeout = setTimeout(() => {
        setCore({...core,"loading" : false})
      }, 1500);
      const cekLogin = async() => {
        var cek = await Func.cekLogin();
        if(cek.status == true){
          setUser(cek.data)
        } else {
          navigate("/login")
          Notify({
            "type" : "error",
            "pesan" : "Login tidak valid"
          })
        }
      }
      const getLevel = async() => {
        var post = await Func.post('api/Membership/getData',{
        });
        if(post.status == true){
            setDataLevel(post.data)
        } else {
            navigate("/")
            Notify({
                "type" : "error",
                "pesan" : post.pesan
            })
        }
        
      }
      cekLogin();
      getLevel();
      return () => {
        clearTimeout(timeout);
      };
    }, []);
    function showHidePay(cat){
      if((cat in ViewCatPay)){
          if(ViewCatPay[cat] === "show"){
              setCatPay({...ViewCatPay,[cat] : ""});
          } else {
              setCatPay({...ViewCatPay,[cat] : "show"});
          }
      } else {
          setCatPay({...ViewCatPay,[cat] : "show"});
        }
    }

    useEffect(() => {
        if(SelectLevel && SelectLevel !== ""){
            getPayment()
        }
    }, [SelectLevel]);

    async function getPayment(){
      setPayment({...payment,"loading" : true});
      var post = await Func.post('api/Membership/getPayment',{
        level : SelectLevel?.level
      });
      if(post.status == true){
        SetSelectPay({})
        setPayment({...payment,"data" : post.data,"loading" : false})
      } else {
        Notify({
          type : "error",
          "pesan" : post.pesan
        })
        setPayment({...payment,"data" : "","loading" : false})
      }
    }
  
    async function SelectPayment(id){
      var post = await Func.post('api/Membership/selectPay',{
        'id' : id,
        level : SelectLevel?.level
      });
      if(post.status == true){
        SetSelectPay(post.data)
        setModalKonf({...modalKonfirmasi,"show" : true})
      } else {
        SetSelectPay({})
        Notify({
          type : "error",
          "pesan" : post.pesan
        })
      }
    }
  
    async function postMember(){
      setLoadingPost(true)
      try {
        var post = await Func.post('api/Membership/post',{
          'id' : SelectedPayment?.id,
          level : SelectLevel?.level
        });
        if(post.status == true){
          Notify({
            "type" : "success",
            "pesan" : "Deposite berhasil dibuat, Silahkan selesaikan pembayaran"
          })
          navigate("/membership/detail/"+post.sid)
        } else {
          Notify({
            type : "error",
            "pesan" : post.pesan
          })
        }
      } catch (error) {
        
      }
      setLoadingPost(false)
    }
  
    function cancelKonfirmasi(){
      setModalKonf({...modalKonfirmasi,"show" : false})
      SetSelectPay({})
    }
    return (
      <div id="membership">
      <Helmet>
        <title>Upgrade Membership</title>
      </Helmet>
        <div className='d-flex justify-content-center mt-4'>
        {core.loading == true ? (
          <Skeleton height={300} containerClassName="col-lg-7 col-12"></Skeleton>
        ) : (
          <div className='col-lg-7 col-12'>
            <div className='amr-card'>
              <span style={{fontSize: "18px"}} className='amr-hr fw-bold'>Pilih Level Membership</span>
              <div className='row' style={{marginTop: "20px"}}>
              {dataLevel.length == 0 ? (
                <div className='col-12' style={{"padding" : "0px 20px"}}>
                <Alert variant={"success"}>
                    <span className='amr-font-2 h6 fw-bold text-success'>
                        Tidak ada level yang tersedia untuk di upgrade :)
                    </span>
                </Alert>
                </div>
              ) : Object.entries(dataLevel).map((elm,i)=>{
                return (
                    <div className={'col-lg-6 col-12 mb-3 '} onClick={()=> setSelectLevel(elm[1])} key={i}>
                        <div className={'level-membership '+(SelectLevel?.level == elm[0] ? "amr-selected" : "")}>
                            <div className="amr-font-2 h6 level">
                            <FontAwesomeIcon className='me-1 text-warning' icon={faCrown} /> {elm[1]?.nama.toLocaleUpperCase()}
                            </div>
                            <div className='amr-font-3 fw-bold harga'>
                               Rp {Func.rp(elm[1]?.harga)}
                            </div>
                        </div>
                    </div>
                )
              }) 
              }
              </div>

              <div className="amr-card-content" ref={CardPay} >
                  {payment.loading === true ? (
                      <Skeleton style={{borderRadius: '10px'}} containerClassName='col-lg-12 mb-2' height={250}></Skeleton>
                  ) : payment.data?.length < 1 || !payment.data ? (
                          <div></div>
                      ) : (
                        <>
                        <div className='amr-hr amr-font-2 fw-bold h6 mb-3 mt-3'>Pilih Pembayaran</div>
                        {Object.entries(payment.data).map((elm,key) => {
                          var detectShow = ([elm[0]] in ViewCatPay) === true ? (ViewCatPay[elm[0]] === "show" ? "tutup" : "") : "";
                          return(
                              <div key={"pay-cat-"+elm[0]} className="col-12 mb-3">
                                  <div className={"card-payment "+detectShow} data-bs-toggle="collapse" href="#collapseExample" aria-expanded="false">
                                      <div className="nama">{Func.getNamePay(elm[0])}</div>
                                      <div className="payment-content">
                                          <div className="row">
                                              {elm[1].map((payCat) =>{
                                                  return(
                                                      <div onClick={()=> {SelectPayment(payCat.id)}} key={"pay-body"+payCat.id} className="col-lg-6 col-12 col-md-6 col-sm-6 mb-3">
                                                          <div className={"payment-body "+(SelectedPayment?.id === payCat.id ? "amr-selected" : "")}>
                                                              <div className="form-img">
                                                                  <img src={payCat.img} alt=""/>
                                                              </div>
                                                              <div className="note-payment">
                                                                  <div>{payCat.nama}</div>
                                                                  <div>Rp {Func.rp(payCat?.total_harga?.total_bayar ?? 0)}</div>
                                                              </div>
                                                          </div>
                                                      </div>
                                                  )
                                              })}
                                          </div>
                                      </div>
                                  </div>
                                  <div className={"card-payment-body "+detectShow}>
                                      <div className="card-payment-list cursor-pointer" onClick={() => showHidePay(elm[0])}>
                                          <div className="all-img amr-scroll">
                                          {elm[1].map((payCat) =>{
                                              return(
                                                  <img key={"img-key-"+payCat.id} src={payCat.img} alt=""/>
                                              )
                                          })}
                                          </div>
                                          <div className="for-ico"></div>
                                      </div>
                                  </div>
                              </div>
                          )
                      })}
                      </>
                          )
                      }  
                  </div>

            </div>
          </div>
        )}
        </div>
  
        {modalKonfirmasi.show === true ? (
          <Modal show={modalKonfirmasi.show} onHide={() => setModalKonf({...modalKonfirmasi,"show" : false})}>
          <Modal.Body style={{"backgroundColor" : "rgb(39, 39, 48)"}}>
              <div className="text-end mb-3 position-absolute" style={{"right": "15px",zIndex : "1"}}>
                  <span onClick={() => cancelKonfirmasi()} type="button" data-bs-dismiss="modal" aria-label="Close">
                      <i className="fa-solid fa-xmark" style={{"fontSize": "22px"}}></i>
                  </span>
              </div>
              <h5 className="text-center fw-bold amr-font-1">Detail Upgrade Membership</h5>
              <div className="content-konfirmasi">
              <div className="judul">Ringkasan Membership</div>
                <div className="data">
                    <div className="kiri">Level Sekarang</div>
                    <div className="kanan">{user.level_khusus.toLocaleUpperCase()}</div>
                </div>
                <div className="data">
                    <div className="kiri">Level Upgrade</div>
                    <div className="kanan">{SelectLevel?.nama?.toLocaleUpperCase()}</div>
                </div>
                                <div className="data">
                    <div className="kiri">Harga</div>
                    <div className="kanan">Rp {Func.rp(SelectLevel?.harga)}</div>
                </div>

              <div className="judul">Ringkasan Pembayaran</div>
                <div className="data">
                    <div className="kiri">Pembayaran Dipilih</div>
                    <div className="kanan">{(SelectedPayment.nama)}</div>
                </div>
                <div className="data">
                    <div className="kiri">Total Fee</div>
                    <div className="kanan">Rp {Func.rp(SelectedPayment?.total_harga?.total_fee)}</div>
                </div>
                <div className="data">
                    <div className="kiri">Total Bayar</div>
                    <div className="kanan text-warning">Rp {Func.rp(SelectedPayment?.total_harga?.total_bayar)}</div>
                </div>
  
              <div className='text-end mt-4'>
                    <span onClick={() => cancelKonfirmasi()} data-bs-dismiss="modal" className="p-4" style={{"cursor": "pointer"}}>Cancel</span>
                           
                    {LoadingPost === true ? (
                        <button className="btn amr-btn" style={{"borderRadius": "20px"}}>Beli Sekarang
                            <i className="fa-solid fa-spinner amr-loader"></i>
                        </button>
                    ) : (
                        <button onClick={()=> postMember()} className="btn amr-btn" style={{"borderRadius": "20px"}}>Beli Sekarang
                        <i className="fa-solid fa-arrow-right ms-2"></i>
                        </button>
                    )}
              </div>
              </div>
          </Modal.Body>
        </Modal>
        ): ""}
  
      </div>
    )
}

export default Membership