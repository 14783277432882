import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './style/amr-core.css';
import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css';
import { CirclesWithBar } from 'react-loader-spinner';
import { Func } from './glob/Func';
const root = ReactDOM.createRoot(document.getElementById('root'));
var preloader = {
    show : true,
    url : null
};
const st_preloader = localStorage.getItem('index_preload');

function editPreloader(newData){
    preloader = newData;
    var elm = document.getElementById("amr-preloader-index");
    var img_preload = document.getElementById("preloader-logo");
    var default_preload = document.getElementById("preloader-default")
    if(preloader.show == false){
        elm.style.display = "none";
    } else {
        elm.style.display = "flex";
        try {
            var img = document.getElementById("img-preloader");
            if(preloader.url){
                default_preload.style.display = "none";
                img_preload.style.display = "flex";
                img.setAttribute("src",preloader.url)
                localStorage.setItem('index_preload', "img");
            } else {
                img_preload.style.display = "none";
                default_preload.style.display = "flex";
                localStorage.setItem('index_preload', "default");
            }
        } catch (error) {
        }
    }
}
root.render(
    <>
    <div className="amr-preloader" id="amr-preloader-index">
        <div className="progres" id="preloader-logo" style={{display: st_preloader == 'img' ? "" :"none" }}>
            <img id="img-preloader" src={Func.url+"no-back.png"} className="img-preloader" />
        </div>
        <div id="preloader-default" style={{display: st_preloader == 'default' ? "" :"none" }}>
            <CirclesWithBar
                height="100"
                width="100"
                color="#4fa94d"
                wrapperStyle={{}}
                wrapperclassName=""
                visible={true}
                outerCircleColor=""
                innerCircleColor=""
                barColor=""
                ariaLabel='circles-with-bar-loading'
            /> 
        </div>
    </div>
       <App preloader={{data: preloader,editData: editPreloader}}/>
    </>
    /* */
);