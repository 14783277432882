import React, { useEffect, useRef, useState } from 'react'
import Skeleton from 'react-loading-skeleton';
import { useNavigate } from 'react-router-dom';
import { Func } from '../../../glob/Func';
import Notify from '../../../glob/Notify';

function Password(props) {
  const {user} = props;
  const [input,setInput] = useState();
  const [core,setCore] = useState({"form" : true});
  const amr_ref = {
    "password" : useRef(),
    "pass_1" : useRef(),
    "pass_2" : useRef()
  };
  const navigate = useNavigate();
  function changeInput(key,value){
    setInput({...input,[key] : value});
  }
  useEffect(() => {
    Func.setRule(true)
    var timer = setTimeout(() => {
      setCore({form : false})
    }, 1000);
    return () => {
      Func.setRule(false)
      clearTimeout(timer)
    };
  }, []);

  async function postData(){
    setCore({...core,"submit" : true})
    try {
      var post = await Func.post('api/Profile/change_pass',{
        input : input
      });
      console.log(post)
      if(post.status == true){
        navigate("/login")
        Notify({
          "type" : "success",
          "pesan" : "Berhasil diubah"
        })
      } else {
        Notify({
          "type" : "error",
          "pesan" : post.pesan
        })
      }
    } catch (error) {
      
    }
    setCore({...core,"submit" : false})
  }

  function focusInput(ref){
    ref.current.focus();
  }
  return (
    <div>
      <div className='row'>
          {core.form == true ? (
            <Skeleton className='col-12' height={300}></Skeleton>
          ) : (
            <div className='col-12'>
              <div className='amr-card2'>
                  <div className='row'>
                    <div className='col-lg-12 amr-form'>
                        <input type="password" ref={amr_ref.password} className='amr-input' onChange={(e)=> changeInput("password",e.target.value)} required></input>
                        <label onClick={()=> focusInput(amr_ref.password)} className='amr-label'>Password Sekarang</label>
                    </div>
                    <div className='col-lg-6 amr-form'>
                        <input ref={amr_ref.pass_1} type="password" className='amr-input' onChange={(e)=> changeInput("pass_1",e.target.value)} required></input>
                        <label onClick={()=> focusInput(amr_ref.pass_1)} className='amr-label'>Password Baru</label>
                    </div>
                    <div className='col-lg-6 amr-form'>
                        <input ref={amr_ref.pass_2} type="password" className='amr-input' onChange={(e)=> changeInput("pass_2",e.target.value)} required></input>
                        <label onClick={()=> focusInput(amr_ref.pass_2)} className='amr-label'>Konfirmasi Password</label>
                    </div>
                    <div className='col-12 text-end'>
                      <button onClick={()=>postData()} className='btn amr-btn'>
                        Simpan Perubahan
                        {core?.submit == true ? (
                          <i className="fa-solid fa-spinner amr-loader"></i>
                        ) : ""}
                      </button>
                    </div>
                  </div>
              </div>
          </div>
          )}
      </div>
    </div>
  )
}

export default Password