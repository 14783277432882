import React, { useEffect, useState } from 'react'
import Skeleton from 'react-loading-skeleton';
import { useNavigate, useParams } from 'react-router-dom';
import { Func } from '../../glob/Func';
import Notify from '../../glob/Notify';

function DepositeDetail() {
    const {sid} = useParams();
    const [data,setData] = useState();
    const [data_pay,setDataPay] = useState();
    const [data_srv,setDataSrv] = useState();
    const [data_harga,setDataHarga] = useState();
    const [data_target,setDataTarget] = useState();
    const [QrisImage,setQrisImg] = useState();
    const navigate = useNavigate();
    var QRCode = require('qrcode')

    const makeQR = async(qr) => {
        var qrcode = QRCode.toDataURL(qr, {
            type: 'image/jpeg',
            width: 200,
            height: 200,
            colorDark : "#000000",
            colorLight : "#ffffff",
        });
        return await qrcode;
    }
    useEffect(() => {
        const getData = async() => {
            var post = await Func.post("api/Deposite/getData",{
                sid : sid
            })
            if(post.status == true){
                try{
                    setData(post.data);
                    setDataPay(JSON.parse(post.data.data_pay))
                    //setDataSrv(JSON.parse(post.data.data_srv))
                    setDataHarga(JSON.parse(post.data.data_harga))
                    //setDataTarget(JSON.parse(post.data.data_target))
                    
                    var dp = JSON.parse(post.data.data_pay);
                    if(dp?.type_api == 'qris'){
                        if(post?.data?.pembayaran?.pay_data){
                            setQrisImg( await makeQR(post.data.pembayaran.pay_data))
                        }
                    }
                } catch(err){
                    Notify({
                        "type" : "error",
                        "pesan" : "Internal error 411"
                    })
                }
            } else {
                navigate("/")
                Notify({
                    "type" : "error",
                    "pesan" : post.pesan
                })
            }
        }
        getData()
    }, []);
    
  return (
    <>
        <div className='row'>
            {!data ? (
                <Skeleton containerClassName='col-lg-4' height={300}></Skeleton>
            ) : (
                <div className="col-lg-4">
                <div className="amr-card2 mb-2" style={{"overflow": "hidden"}}>
                {data?.pembayaran?.status == 'Exp' ? (
                    <div className="text-center">
                            <i style={{fontSize: "90px"}} className="fa-solid text-danger fa-circle-exclamation animate__animated animate__jackInTheBox animate__repeat-3"></i>
                            <div>
                                <span className="amr-font-2 fw-bold" style={{color: "rgb(156 163 175/1)"}}>
                                    Silahkan lakukan transaksi kembali
                                </span>
                            </div>
                        </div>
                ) : data?.pembayaran?.status == 'Paid' ? (
                    <>
                        <div className="text-center batas">
                            <i style={{fontSize: "90px"}} className="fa-solid text-success fa-thumbs-up animate__animated animate__jackInTheBox animate__repeat-3"></i>
                            <div className="mt-2">
                                <span className="amr-font-2 fw-bold" style={{color: "rgb(156 163 175/1)"}}>
                                    Terimakasih anda telah melakukan pembayaran
                                </span>
                            </div>
                        </div>
                        <div className="d-flex justify-content-between amr-item-detail mt-3">
                            <div className="amr-kiri amr-font-1">Waktu Pembayaran</div>
                            <div className="amr-kanan amr-font-3 text-warning">{data.pembayaran.dibayar}</div>
                        </div>
                        <div className="d-flex justify-content-between amr-item-detail">
                            <div className="amr-kiri amr-font-1">Total Pembayaran</div>
                            <div className="amr-kanan amr-font-3 text-danger">Rp {Func.rp(data?.bayar)} <i className="fa-solid fa-copy ico-copy"></i></div>
                        </div>
                    </>
                ) : (
                    <>
                        <div className="text-center amr-font-1 h5 batas">Pembayaran</div>
                            <div className="content-pembayaran mt-3">
                                <div className="d-flex justify-content-between amr-item-detail mb-2">
                                    <div className="amr-kiri amr-font-1">Pembayaran</div>
                                    <div className="amr-kanan amr-font-3">{data_pay.nama}</div>     
                                </div>                                
                                <div className="d-flex justify-content-between amr-item-detail mb-2">
                                    <div className="amr-kiri amr-font-1">Total Pembayaran</div>
                                    <div className="amr-kanan amr-font-3 text-danger">Rp {Func.rp(data?.bayar)} <i onClick={()=> Func.copy(data?.total_bayar) } className="fa-solid fa-copy ico-copy"></i></div>     
                                </div>                                
                                {data_pay.type_api == "qris" ? (
                                    <div className="qris ">
                                        <img style={{height : "100%",width : "100%","objectFit" : "contain"}} src={QrisImage}></img>
                                    </div>
                                ) : data_pay.type_api == "url" ? (
                                    <div className="text-center">
                                        <a className="btn btn-primary btn-sm" href="{data.pembayaran.url_checkout ?? '-'}" target="_blank" rel="noopener noreferrer">
                                            Lanjutkan Pembayaran
                                        </a>    
                                    </div>  
                                    ) : data_pay.type_api == "img" ? (
                                        <div className="text-center" style={{position: "relative"}}>
                                            <img src={data_pay.target} className="img-pay" alt=""/>
                                        </div>  
                                    ) : (
                                        <div className="d-flex justify-content-between amr-item-detail">
                                            <div className="amr-kiri amr-font-1">Target Pembayaran</div>
                                            <div className="amr-kanan amr-font-3 text-warning">{data?.pembayaran?.pay_data ?? data_pay?.target ?? null} <i onClick={() => Func.copy(data?.pembayaran?.pay_data ?? data_pay?.target ?? null)} className="fa-solid fa-copy text-primary ico-copy"></i></div>
                                        </div>
                                    )
                                }
                            </div>
                    </>
                    )
                }
                        
                </div>
            </div>
            )}


            {!data && !data_pay ? (
                <Skeleton containerClassName='col-lg-8' height={500}></Skeleton>
            ) : (
                <div className="col-lg-8">
                    <div className="amr-card2 mb-2">
                        <div id="pembelian">
                            <div className="title-history">
                                <div className="judul amr-font-1">Tanggal Pembelian</div>
                                <div className="value amr-font-2">{data.tanggal}</div>
                            </div>
                            <div className="title-history">
                                <div className="judul amr-font-1">Nomor Pemesanan</div>
                                <div className="value amr-font-2">{data.sid}</div>
                            </div>
                            <div className="title-history">
                                <div className="judul amr-font-1">Metode Pembayaran</div>
                                <div className="value amr-font-2">{data_pay.nama}</div>
                            </div>
                            <div className="title-history">
                                <div className="judul amr-font-1">Status Pesanan</div>
                                <div className="value amr-font-2">{data.status}</div>
                            </div>
                        </div>
                    </div>

                    <div className="amr-card2" style={{overflow:"hidden"}}>
                        <div className="d-flex align-items-center batas">
                            <img className="img-cat" style={{objectFit: "contain"}} src={data?.payment?.img} alt=""></img>
                            <div className="ms-3 name-cat">Deposite Saldo</div>
                        </div>
                        <div className="mt-2" id="keterangan">
                            <div className='col-12 text-center'>
                                <span className="amr-font-1" style={{fontSize: "18px"}}>Detail Pembelian</span>
                            </div>
                            <hr/>
                            <div className="mt-3 batas" id="detail_order">
                                <div className="d-flex justify-content-between mb-1">
                                    <div className="key amr-font-1">Item</div>
                                    <div className="val amr-font-2">Deposite Saldo</div>
                                </div>
                                <hr/>
                                <div className="d-flex justify-content-between mb-1">
                                    <div className="key amr-font-1">Saldo didapatkan</div>
                                    <div className="val amr-font-2">Rp {Func.rp(data.saldo)}</div>
                                </div>
                                <hr/>
                                <div className="d-flex justify-content-between mb-1">
                                    <div className="key amr-font-1">Jumlah</div>
                                    <div className="val amr-font-2">Rp {Func.rp(data.jumlah)}</div>
                                </div>
                                <hr/>
                                <div className="d-flex justify-content-between mb-1">
                                    <div className="key amr-font-1">Fee</div>
                                    <div className="val amr-font-2">Rp {Func.rp(data_harga.total_fee)}</div>
                                </div>
                            </div>

                            <div className="d-flex justify-content-between" id="total_bayar">
                                <div className="amr-font-1">Total Pembayaran</div>
                                <div className="amr-font-3">Rp {Func.rp(data_harga.total_bayar)}<i onClick={() => Func.copy(data_harga.total_bayar)} className="fa-solid fa-copy ico-copy"></i></div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    </>
  )
}

export default DepositeDetail