import './style/App.css';
import {Home,Login,Order, History, Search, SearchOrder, ContactUs, Register, Verifikasi} from './comp';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from 'react-router-dom';
import {Navbar,Footer} from './layout';
import 'react-notifications/lib/notifications.css';
import React, { useEffect, useState } from 'react';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import Skeleton,{ SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { Func } from './glob/Func';
import { Deposite,DepositeDetail,Membership,MembershipDetail,Profile, ProfileEdit } from './comp/AkunLogin';
import { Helmet } from 'react-helmet';

function App(props) {
  const index_preloader = props?.preloader;
  const [data,setData] = useState();
  const [web,setWeb] = useState();
  const [display,setDisplay] = useState("none");
  const [headTag,setHeadTag] = useState();
  const [color_react,setColor] = useState();
  function checkImage(url) {
    var image = new Image();
    image.onload = function() {
      if (this.width > 0) {
        index_preloader.editData({...index_preloader.data,"url" : url,show: true})
      }
    }
    image.onerror = function() {
      index_preloader.editData({...index_preloader.data,"url" : url,show: false})
    }
    image.src = url;
  }
  const getAPP = async()=> {
    try {
      let post = await Func.post('api/App');
      if(post.status == true){
        setWeb(post.data)
        if(post.data?.head_tag){
          setHeadTag(JSON.parse(post.data?.head_tag?.value))
        }
        if(post.data?.color_react){
          setColor(JSON.parse(post.data?.color_react?.value))
        }
        if(post.data?.logo_preloader){
          if(post.data?.logo_preloader?.value.length > 0){
            checkImage(post.data?.logo_preloader?.value)
          } else {
            index_preloader.editData({...index_preloader.data,"url" : null,show: true})
          }
        } else {
          index_preloader.editData({...index_preloader.data,"url" : null,show: true})
        }
      }
    } catch (error) {
      //console.log(error)
    }
    setTimeout(() => {
      setDisplay("")
      index_preloader.editData({...index_preloader.data,show: false})
    }, 2000);
  }
  useEffect(() => {
    if(color_react){
      const root = document.querySelector(':root');
      if(color_react.body){
        root.style.setProperty('--amr-color-body', color_react.body);
      }
      if(color_react.header){
        root.style.setProperty('--amr-color-header', color_react.header);
      }
      if(color_react.navbar){
        root.style.setProperty('--amr-color-navbar', color_react.navbar);
      }
      if(color_react.footer){
        root.style.setProperty('--amr-color-footer', color_react.footer);
      }

    }
  }, [color_react]);
  useEffect(() => {
    getAPP();
  }, []);
  return (
    <>
    <div className='app-js' style={{display: display}}>
    <Helmet>
      <title>{headTag?.og_title}</title>
      <meta name="description" content={headTag?.description} />
      <meta property="og:title" content={headTag?.og_title} />
      <meta property="og:description" content={headTag?.description}/>
      <meta property="og:image" content={headTag?.og_image}/>
      <meta property="og:image:alt" content={headTag?.og_title} />
      <meta property="og:site_name" content={headTag?.og_title} />
      <meta name="Keywords" content={headTag?.keywords} />    
      <meta name="author" content={headTag?.author}/>
      <link rel="icon" href={headTag?.icon}/>
      <link rel="apple-touch-icon" href={headTag?.icon_apple}/>
      
    </Helmet>
    <NotificationContainer></NotificationContainer>
      <SkeletonTheme baseColor="#202020" highlightColor="#444">
        <Router>
        <Navbar Web={{data : web,setData : setWeb}} user={{data,setData}}/>
            <div className="amr-content">
              <div className="row mt-3">
                <div className='col-12'>
                  <Routes>
                    <Route path="/" element={<Home />}></Route>
                    <Route path="/lacak-pesanan" element={<SearchOrder />}></Route>
                    <Route path="/login" element={<Login user={{data,setData}} />}></Route>
                    <Route path="/register" element={<Register />}></Route>
                    <Route path="/verifikasi/:token" element={<Verifikasi />}></Route>
                    <Route path="/game-search" element={<Search />}></Route>
                    <Route path="/kontak-kami" element={<ContactUs />}></Route>
                    <Route path="/order/:slug" element={<Order />}></Route>
                    <Route path="/order/detail/:sid" element={<History />}></Route>
                    <Route path="/profile" element={<Profile />}></Route>
                    <Route path="/profile/edit" element={<ProfileEdit />}></Route>
                    <Route path="/deposite" element={<Deposite />}></Route>
                    <Route path="/deposite/detail/:sid" element={<DepositeDetail />}></Route>
                    <Route path="/membership" element={<Membership />}></Route>
                    <Route path="/membership/detail/:sid" element={<MembershipDetail />}></Route>
                </Routes>
                </div>
              </div>
            </div>
        <Footer Web={{data : web,setData : setWeb}}></Footer>
        </Router>
      </SkeletonTheme>
    </div>
    </>
  );
}

export default App;
