import { cleanup } from '@testing-library/react';
import React, { useEffect, useRef, useState } from 'react'
import { Headphones} from 'react-feather'
import Skeleton from 'react-loading-skeleton';
import { Func } from '../glob/Func';
import Notify from '../glob/Notify';

function ContactUs() {
  const [Core,setCore] = useState({"loading" :true});
  const [BtnLoading,setBtn] = useState(false);
  const limitPesan = 220;
  const elmInput = {
    "nama" : useRef(),
    "no_hp" : useRef(),
    "pesan" : useRef(),
  };
  const [type,setType] = useState();
  const [nama,setNama] = useState();
  const [no_hp,setNohp] = useState();
  const [pesan,setPesan] = useState();

  useEffect(() => {
    Func.setRule(true);
    setTimeout(() => {
        setCore({...Core,"loading" : false});
    }, 1000);
    return () => {
        Func.setRule(false);
    }
}, []);
  function ChangePesan(e){
    var isi = e.target.value;
    if((isi?.length ?? 0) > limitPesan){
        var potong = isi.substr(0,limitPesan);
        e.target.value = potong;
        setPesan(potong)
    } else {
        setPesan(isi);
    }
  }

  async function postData(){
    setBtn(true)
    try {
        let post = await Func.post('api/ContactUs/post',{
            type: type,
            nama : nama,
            no_hp : no_hp,
            pesan : pesan
        });
        if(post.status == true){
            Notify({
                "type" : "success",
                "pesan" : "Terimakasih,kami akan segera membalas pesan anda"
            })
        } else {
            Notify({
                "type" : "error",
                "pesan" : post?.pesan
            })
        }   
        setBtn(false)
    } catch (error) {
        setBtn(false)
    }
  }
  function onLabelClick(ref){
    ref.current.focus()
  }
  return (
    <div className='mt-3'>
        <div className="row">
            <div className='col-12'>
                <div className='d-flex justify-content-center'>
                    {Core?.loading == true ? (
                        <Skeleton height={300} containerClassName="col-lg-8 col-12" />
                    ): (
                        <div className='col-lg-8'>
                        <div className='amr-card2'>
                            <h6 className='amr-font-1'><Headphones size={16} className='me-2'/> Hubungi Kami</h6>
                            <hr/>
                            <div className='row'>
                                <div className='col-6 amr-form'>
                                    <select className='' onChange={(e) => setType(e.target.value)}>
                                        <option value="">Pilih Salah satu</option>
                                        <option value="order">Masalah Order</option>
                                        <option value="payment">Pembayaran</option>
                                        <option value="buat-web">Ingin buat web seperti ini?</option>
                                        <option value="kerjasama">Kerja Sama Order API/H2H</option>
                                        <option value="feedback">Feedback</option>
                                    </select>
                                </div>
                                <div className='col-6 amr-form'>
                                    <input ref={elmInput.nama} onChange={(e) => setNama(e.target.value)} className='amr-input' required/>
                                    <label onClick={()=>onLabelClick(elmInput.nama)} className='amr-label'>Username/Nama</label>
                                </div>
                                <div className='col-lg-12 amr-form'>
                                    <input type="number" ref={elmInput.no_hp} onChange={(e) => setNohp(e.target.value)} className='amr-input' required/>
                                    <label onClick={()=>onLabelClick(elmInput.no_hp)} className='amr-label'>Nomor Whatsapp</label>
                                </div>
                                <div className='col-lg-12 amr-form'>
                                    <textarea rows={4} onChange={ChangePesan} ref={elmInput.pesan} className='' required placeholder='Masukan Pesan anda'/>
                                    <div className='text-end' style={{fontSize: "14px"}}>{pesan?.length ?? 0}/{limitPesan}</div>
                                </div>
                                <div className='col-12 text-end'>
                                    <button onClick={()=> postData()} className='amr-btn btn w-100'>
                                    Kirim {BtnLoading == true ? (
                                    <i className="fa-solid fa-spinner amr-loader"></i>
                                ) : (
                                    <></>
                                )}
                                    </button>
                                </div>
                            </div>
                        </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    </div>
  )
}

export default ContactUs