import axios from 'axios';
import Notify from './Notify';
class Func{
    constructor(){
        this.url = "https://api.botwa-amr.my.id/";
        // https://api.botwa-amr.my.id/
        // https://smm.amrcode.dev/
        // https://localhost.topup2/
        this.hover_home = "https://www.transparentpng.com/download/smile/gGsgum-emoji-feliz-png-emoticon-smile-clipart-full-size.png";
        this.rule = false;
    }
    setRule(status){
        this.rule = status;
    }
    async post(tambah,data = {},headers = {},postToken = true){
        var rules,allow = true,trigger_warning = false;
        if(this.rule == true){
            rules = await localStorage.getItem("rulesPost");
            rules = window.atob(rules);
            var now = new Date();
            if(this.isJson(rules)){
                rules = JSON.parse(rules);
                var time = (rules?.last ?? "") == "" ? new Date() : new Date(rules.last);
                var time_allow = (rules?.time_allow ?? "") == "" ? new Date() : new Date(rules.time_allow);
                var warning = rules?.warning ?? 0;
                var detikan = Math.round((now - time) / 1000);
                
                if(detikan <= 3 && now >= time_allow){ //Penabahan validasi waktu sekarang dengan time allow supaya warning tidak ditambah jika ada time allow
                    warning++;
                    rules = {...rules,"warning" : warning};
                    trigger_warning = true;
                }
                if(warning >= 3){
                    let minutesToAdd = 1;
                    var tmbh1 = new Date(now.getTime() + minutesToAdd * 60000);
                    rules = {...rules,"time_allow" : tmbh1,warning : 0};
                    allow = false;
                }
                if(trigger_warning == false){
                    rules = {...rules,"warning" : 0};
                }
                var new_rules = {...rules,"last" : now.toString()};
                localStorage.setItem('rulesPost', btoa(JSON.stringify(new_rules)))
                if(now <= time_allow){
                    return {status : "error","pesan" : "Anda terlalu sering melakukan click,silahkan coba lagi dalam 1 menit"}
                }
            } else {
                var new_rules = {"last" : now.toString()};
                localStorage.setItem('rulesPost', btoa(JSON.stringify(new_rules)))
            }
        }
        var token = await this.getToken();
        if(token && postToken == true){
            headers = {
                ...headers,
                'Content-Type': 'application/json',
                'Authorization': "Bearer " +token
            }
        }
        var post = await axios.post(this.url+tambah, data,{headers : headers})
        return post.data ?? null;
    }
    copy(text){
        navigator.clipboard.writeText(text)
        Notify({
            "type" : "success",
            "pesan" : "Text berhasil disalin"
        })
    }
    rp(angka){
        try {
            var rupiah = '';		
            var angkarev = angka.toString().split('').reverse().join('');
            for(var i = 0; i < angkarev.length; i++) if(i%3 == 0) rupiah += angkarev.substr(i,3)+'.';
            return rupiah.split('',rupiah.length-1).reverse().join('');
        } catch (error) {
            console.log("ERROR FUNC RP")
            return angka;
        }
    }
    async getToken(){
        var token = await localStorage.getItem("amr_token");
        return token;
    }
    async setToken(token){
        return localStorage.setItem('amr_token', token)
    }
    async removeToken(){
        return localStorage.removeItem("amr_token");
    }
    async cekLogin(){
        var token = await this.getToken();
        if(!token){
            return {"status" : false,"pesan" : "Token Kosong"};
        }
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': "Bearer " +token
        }
        var post = await this.post('api/auth/cek-login',{},headers,false);
        return post;
    }
    ScrollUp(){
        window.scrollTo(0, 0);
    }
    timeOnly(date){
        try {
            var spTime = date.split(" ");
            var timeOnly = spTime[1] ?? "-";
            timeOnly = timeOnly.substr(0,timeOnly.length - 3);
            return timeOnly;
        } catch (error) {
            return date;
        }
    }
    getNamePay(nama){
        var catf = "";
        if(nama === 'member'){
            catf = "Saldo Member";
        } else if(nama === 'qris'){
            catf = "QRIS";
        } else if(nama === 'ewallet'){
            catf = "E - Wallet";
        } else if(nama === 'va'){
            catf = "Virtual Account";
        } else if(nama === 'retail'){
            catf = "Convenience Store";
        } else if(nama === 'bank'){
            catf = "Transfer Bank";
        } else {
            catf = 'Other';
        }
        return catf;
    }
    isJson(data) {
        try {
          JSON.parse(data);
          return true;
        } catch (e) {
          return false;
        }
    }
    name2Letters(nama){
        try {
            var potong = nama.split(" ");
            var final;
            if(potong.length == 1){
            final = nama.substr(0,2);
            } else {
            var create = potong[0].substr(0,1) + potong[1].substr(0,1);
            final = create;
            }
            return final.toLocaleUpperCase();
        } catch (error) {
            return "ERR";
        }
    }
}
const F = new Func();
export {F as Func};