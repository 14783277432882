import React from 'react'
import {NotificationContainer, NotificationManager} from 'react-notifications';

function Notify(props) {
  var type = props.type ?? 'info';
  var title = props.title ?? '';
  var pesan = props.pesan ?? '';
  var time = props.time ?? 5000;
  switch (type) {
    case 'info':
    NotificationManager.info(pesan,title,time);
    break;
    case 'success':
    NotificationManager.success(pesan, title,time);
    break;
    case 'warning':
    NotificationManager.warning(pesan, title, time);
    break;
    case 'error':
    NotificationManager.error(pesan, title, time, () => {
        //alert('callback');
    });
    break;
  }
}
export default Notify