import React from 'react'

function Security() {
  return (
    <div>
      <div className='row'>
          <div className='col-12'>
              <div className='amr-card2'>
                  INI Keamanan
              </div>
          </div>
      </div>
    </div>
  )
}

export default Security