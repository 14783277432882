import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { Func } from '../glob/Func';
import Notify from '../glob/Notify';

function ResetPassword(props) {
    const {modal} = props;
    const [core,setCore] = useState();
    const [target,setTarget] = useState();
    useEffect(() => {
        Func.setRule(true);
        return () => {
            Func.setRule(false)
        };
    }, []);
    async function searchUsername(){
        setCore({...core,"btn_search" : true})
        try {
            var post = await Func.post('api/auth/resetPass',{
                no_hp : target
            });
            if(post.status == true){
                modal.setData({...modal.data,"show" : false})
                Notify({
                    "type" : "success",
                    "pesan" : post.pesan
                })
            } else {
                Notify({
                    "type" : "error",
                    "pesan" : post.pesan
                })
            }
        } catch (error) {
            
        }
        setCore({...core,"btn_search" : false})
    } 
  return (
    <div>
        <div className='row'>
            <div className='col-lg-12 amr-form'>
                <input onChange={(e)=> setTarget(e.target.value)} className='amr-input' required></input>
                <label className='amr-label'>Nomor HP</label>
            </div>
            <div className='text-end'>
                <button onClick={()=> searchUsername()} className='amr-btn btn'>Cari 
                    {core?.btn_search == true ? (
                        <i className="fa-solid fa-spinner ms-2 amr-loader"></i>
                    ) : (
                        <FontAwesomeIcon className='ms-2' icon={faSearch}/> 
                    )}
                </button>
            </div>
        </div>
    </div>
  )
}

export default ResetPassword