import React, { useEffect, useState } from 'react'
import { Table } from 'react-bootstrap'
import Skeleton from 'react-loading-skeleton'
import { useNavigate } from 'react-router-dom';
import { Func } from '../../glob/Func';
import Notify from '../../glob/Notify';
import PaginationAMR from '../../glob/PaginationAMR';

function OrderUser(props) {
    const {dataProfile} = props;
    const [dataOrder,setDataOrder] = useState();
    const navigate = useNavigate();
    const [SearchInput,setSearchInput] = useState(); 
    const [TypeSearch,setTypeSearch] = useState(); 
    const [AmrPagi,setAmrPagi] = useState();
    
    useEffect(() => {
        if((TypeSearch ?? "") !== "" && (SearchInput ?? "") !== ""){
            getHistory(SearchInput,false,1)
        }
    }, [TypeSearch,SearchInput]);

    async function getHistory(data = '',notif =true,page = 1){
    setDataOrder({...dataOrder,"loading" : true})
    try {
        let post = await Func.post('api/Profile/getOrder',{
            search: data == "" ? SearchInput : data,
            page : page,
            type_search : TypeSearch,
        });
        if(post?.status === true){
        setDataOrder({...dataOrder,"data" : post?.pagination,"loading" : false})
        dataProfile.setData(post?.data)
        } else {
            if(notif == true){
                Notify({
                    "type" : "error",
                    "pesan" : post?.pesan
                })
            }
            setDataOrder({...dataOrder,"loading" : false,"data" : ""})
        }
    } catch (error) {
    setDataOrder({...dataOrder,"loading" : false})
    }
    }

    useEffect(() => {
        getHistory()
        return () => {
            
        };
    }, []);

    function redirect(url){
      Func.ScrollUp();
      navigate(url)
    }
  return (
    <div className='mt-3'>
        <div className='amr-card2'>
        <div className='row mb-3'>
            <div className='col-6'>
                <label className='amr-font-1'>Filter Pencarian</label>
                <select onChange={(e) => setTypeSearch(e.target.value)} style={{"backgroundColor" : "rgb(39, 39, 48)"}} className='form-select text-white'>
                    <option value="">Pilih salah satu</option>
                    <option value="sid">Order ID</option>
                    <option value="status">Status</option>
                </select>
            </div>
            <div className='col-6'>
            <label className='amr-font-1'>Cari</label>
            <input onChange={(e) => setSearchInput(e.target.value)} style={{"backgroundColor" : "rgb(39, 39, 48)"}} className='form-control text-white' placeholder='Cari disini..'/>
            </div>

        </div>
        <div className='table-responsive amr-scroll' style={{"maxHeight" : "350px"}}>
              <Table className='m-0 table-depo'>
                <thead>
                  <tr>
                    <th>Tanggal</th>
                    <th>Order ID</th>
                    <th>Product</th>
                    <th>Layanan</th>
                    <th>Harga</th>
                    <th>Payment</th>
                    <th>Status</th>
                  </tr>
                </thead>
                {dataOrder?.loading == true? (
                  <tbody>
                    <tr>
                        <td className='p-0 m-0' colSpan={100} rowSpan={100}>
                            <div className='w-100 position-relative'>
                                <Skeleton height={150}></Skeleton>
                            </div>
                        </td>
                    </tr>
                  </tbody>
                ) : (dataOrder?.data?.data?.length ?? 0) == 0 ? (
                  <tbody>
                    <tr>
                      <td className='amr-font-1 text-center' colSpan={10}>Tidak ada data yang tersedia</td>
                    </tr>
                  </tbody>
                ) : (
                  <tbody>
                    {dataOrder?.data?.data.map((elm) => {
                        var data_srv = JSON.parse(elm.data_srv);
                        var data_pay = JSON.parse(elm.data_pay);
                      return(
                        <tr style={{cursor:"pointer"}} onClick={()=> redirect("/order/detail/"+elm.sid)} className='depo-tr' key={"depo"+elm.sid}>
                          <td className='amr-font-2 text-center'>{elm.create}</td>
                          <td className='amr-font-2 text-center'>{elm.sid}</td>
                          <td className='amr-font-2 text-center'>{data_srv.category}</td>
                          <td className='amr-font-2 text-center'>{data_srv.layanan}</td>
                          <td className='amr-font-2 text-center'>{Func.rp(elm.total_bayar)}</td>
                          <td className='amr-font-2 text-center'>{data_pay.nama}</td>
                          <td className='amr-font-2 text-center'>
                            {elm.status == "Unpaid" ? (
                              <span className='badge bg-primary'>{elm.status}</span>
                            ) : elm.status == "Sukses" ? (
                              <span className='badge bg-success'>{elm.status}</span>
                            ) : (
                              <span className='badge bg-danger'>{elm.status}</span>
                            )}
                            
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                )}
              </Table>
              </div>
        </div>
        <div className='w-100'>
            <PaginationAMR className="mt-2 text-end" pagi={{
            data: AmrPagi,
            setData : setAmrPagi
            }}
            onChange={getHistory}
            data={{
            per_page : dataOrder?.data?.per_page,
            total : dataOrder?.data?.total
            }}/>
        </div>
    </div>
  )
}

export default OrderUser