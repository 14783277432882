import { faAdd, faCreditCard, faCrown, faEye, faMobileScreenButton, faMoneyBill, faMoneyBillTrendUp, faPlus, faShoppingCart, faTurnUp, faUserEdit, faUserGear } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react'
import { Phone } from 'react-feather';
import { Helmet } from 'react-helmet';
import Skeleton from 'react-loading-skeleton';
import { useNavigate } from 'react-router-dom';
import { Func } from '../../glob/Func';
import Notify from '../../glob/Notify';
import OrderUser from './OrderUser';
import "./Style/Profile.css";

function Profile() {
  const [core,setCore] = useState({"loading" : true});
  const [User,setUser] = useState();
  const [dataOrder,setDataOrder] = useState();
  const [dataProfile,setDataProfile] = useState();
  const navigate = useNavigate();
  const cekLogin = async() => {
    var cek = await Func.cekLogin();
    if(cek.status == true){
      setUser(cek.data)
      setCore({"loading" : false})
    } else {
      navigate("/login")
      Notify({
        "type" : "error",
        "pesan" : "Login tidak valid"
      })
    }
  }
  const getAll = async ()=> {
    var post = await Func.post('api/Profile/getInfoForAkun');
    setDataProfile(post?.data);
  }
  
  
  useEffect(() => {
    cekLogin();
    getAll();
    return () => {

    };
  }, []);

  function redirect(url){
    Func.ScrollUp();
    navigate(url)
  }
  return (
    <div id="profile">
    <Helmet>
      <title>Pengaturan Akun</title>
    </Helmet>
      {core?.loading == true ? (
        <Skeleton height={300}></Skeleton>
      ) : (
        <>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='amr-card2'>
                  <div className='d-flex justify-content-between'>
                    <div className='d-flex justify-content-between'>
                      <div className='ico-user'>
                        {Func.name2Letters(User?.nama)}
                      </div>
                      <div className='ms-3 d-flex flex-column'>
                        <span className='amr-font-2 fw-bold' style={{fontSize: "18px"}}>{User?.nama}</span>
                        <span className='amr-font-1' style={{fontSize: "15px"}}>
                        <FontAwesomeIcon className='me-1 text-warning' icon={faCrown}/> 
                        {User?.level_khusus.toLocaleUpperCase()}
                        </span>
                       
                       
                        <span className='amr-font-2' style={{fontSize: "17px"}}>
                        <FontAwesomeIcon className='me-1 text-warning' icon={faMoneyBill}/>
                         Rp {Func.rp(User?.saldo)} 
                          <span onClick={() => redirect('/deposite')} style={{fontSize: "12px",cursor: "pointer"}} className='badge bg-primary ms-2'><FontAwesomeIcon icon={faAdd}/></span>
                         </span>
                      </div>
                    </div>
                    <div>
                      <button onClick={() => redirect("/profile/edit")} className='btn amr-btn btn-sm fw-bold amr-font-3' style={{borderRadius: "40px"}}>
                        <span className='d-none d-lg-block'>
                          <FontAwesomeIcon className='me-2' icon={faUserEdit}/>Edit Profile
                        </span>
                        <span className='d-block d-lg-none'>
                          <FontAwesomeIcon className='ps-2 pe-2' icon={faUserGear}/>
                        </span>

                      </button>
                    </div>
                  </div>
                  <hr/>
                  <div className='row'>
                    <div className='col-lg-6 mb-2'>
                      <div className='d-flex flex-column'>
                      <span  className='amr-font-2 mb-1' style={{fontSize: "17px"}}>
                      <FontAwesomeIcon icon={faMobileScreenButton} className="text-warning me-2 ms-1"/>
                      <span className='amr-font-2'>{User.no_hp}</span>
                      </span>

                      <span className='amr-font-2 mb-1' style={{fontSize: "17px"}}>
                        <FontAwesomeIcon className='text-warning me-2' icon={faShoppingCart}/>
                        {Func.rp(dataProfile?.jumlah ?? 0)}x Pembelian
                        </span>

                        <span className='amr-font-2 mb-1' style={{fontSize: "17px"}}>
                        <FontAwesomeIcon className='text-warning me-2' icon={faMoneyBillTrendUp}/>
                        Rp {Func.rp(dataProfile?.total ?? 0)} 
                        </span>
                    </div>
                    </div>
                    <div className='col-lg-6'>
              <div className='amr-card2 p-0 m-0 upgrade-membership'>
                  <div className='for-membership p-3'>
                    <span className='amr-font-2'>
                      Dapatkan harga lebih murah dengan Upgrade Membership!
                    </span>
                    <button onClick={() => redirect("/membership")} className='mt-5 btn-upgrade btn-primary amr-font-1 text-white btn mt-2' style={{borderRadius: "40px"}}>
                      Upgrade Sekarang
                      <FontAwesomeIcon className='ms-1 text-warning' icon={faTurnUp}/>
                    </button>
                  </div>
              </div>
            </div>
                  </div>
              </div>
            </div>
            
            <div className='col-12'>
                <div className='amr-card2'>
                  <h5 className='amr-font-1'><FontAwesomeIcon icon={faShoppingCart} className="me-2"/> Pesanan Anda</h5>
                  <hr/>
                  <div className='pesanan'>
                    <div className='item'>
                      <span className="jumlah">{Func.rp(dataOrder?.Unpaid?.total ?? 0)}</span>
                      <span className="status amr-font-1">Belum Bayar</span>
                    </div>
                    <div className='item'>
                      <span className="jumlah">{Func.rp(dataOrder?.Pending?.total ?? 0)}</span>
                      <span className="status amr-font-1">Pending</span>
                    </div>
                    <div className='item'>
                      <span className="jumlah">{Func.rp(dataOrder?.Sukses?.total ?? 0)}</span>
                      <span className="status amr-font-1">Sukses</span>
                    </div>
                    <div className='item'>
                      <span className="jumlah">{Func.rp(dataOrder?.Gagal?.total ?? 0)}</span>
                      <span className="status amr-font-1">Gagal</span>
                    </div>
                  </div>
                  <OrderUser dataProfile={{data: dataOrder ,setData : setDataOrder}}/>
                </div>
            </div>
            
          </div>
        </>
      )}
    </div>
  )
}

export default Profile