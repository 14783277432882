import React, { useEffect, useRef, useState } from 'react'
import { Button, Modal } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import Skeleton from 'react-loading-skeleton';
import { useNavigate } from 'react-router-dom';
import { Func } from '../glob/Func';
import Notify from '../glob/Notify';
import ResetPassword from './ResetPassword';
import "./Style/Login.css";

function Login(props) {
  const [Username,SetUser] = useState();
  const [Password,SetPass] = useState();
  const [Remember,SetRemember] = useState();
  const [ShPass,SetShPass] = useState("password");
  const [Login,SetLogin] = useState(false);
  const [BtnLoading,setBtnLoading] = useState(false)
  const navigate = useNavigate();
  const [modalForget,setModalForget] = useState({"show" : false});
  const input = {
    "user" : useRef(),
    "pass" : useRef()
  };
  useEffect(() => {
    var token = Func.getToken();
    const cekLogin = async () =>{
      var post =  await Func.cekLogin();
      if(post?.status == true){
        Notify({
          "pesan" : "Hallo Selamat datang "+post?.data?.nama,
          "type" : "success"
        })
        navigate("/")
      } else {
        SetLogin(true);
        Func.removeToken();
      }
    }
    if(!token){
      SetLogin(true);
    } else{
      cekLogin();
    }
  }, []);
  async function postLogin(){
    setBtnLoading(true);
    var post = await Func.post('api/auth/Login',{
      "username" : Username,
      "password" : Password,
      "remember" : Remember
    });
    if(post.status == true){
      if(post.pesan == "REDIRECT VERIFIKASI"){
        Notify({
          "pesan" : "Verifikasi terlebih dahulu akun anda",
          "type" : "success"
        })
        setBtnLoading(false);
        navigate("/verifikasi/"+post.token);
      } else {
        Func.setToken(post.jwt)
        Notify({
          "pesan" : "Berhasil login,Selamat datang "+post?.data?.nama,
          "type" : "success"
        })
        props.user.setData(post.data);
        setBtnLoading(false);
        navigate("/")
      }
    } else {
      setBtnLoading(false);
        Notify({
          "pesan" : post.pesan ?? "-",
          "type" : "error"
        })
    }
  }
  return (
    <div>
    <Helmet>
      <title>Login Akun</title>
    </Helmet>
      <div className="row mt-2">
        <div className="col-12">
            <div className="d-flex justify-content-center">
            {Login == false ? (
              <Skeleton height={350} containerClassName='col-12 col-lg-8'></Skeleton>
            ) : (
              <div className="col-lg-8 col-12">
                    <div className="amr-card2">
                        <h4 className="amr-font-1">Masuk</h4>
                        <p className="amr-font-2">Masuk menggunakan Akun terdaftar Kamu.</p>
                        <hr/>
                        
                        <div className="row">
                            <div className="col-12 amr-form mb-2">
                                <input ref={input.user} type="text" className="amr-input" onChange={(e)=>SetUser(e.target.value) } required/>
                                <label onClick={()=> {input.user.current.focus()}} className="amr-label">Masukan Nomor HP</label>
                            </div>
                            <div className="col-12 amr-form mb-3">
                                <input ref={input.pass} type={ShPass} className="amr-input" onChange={(e)=>SetPass(e.target.value) } required/>
                                <label onClick={()=> {input.pass.current.focus()}} className="amr-label">Password</label>
                                <i className={"amr-ico-pass "+(ShPass == "password" ? "fa-solid fa-eye" : "fa-solid fa-eye-slash text-warning")} onClick={() => SetShPass(ShPass == "password" ? "text" : "password")}></i>
                            </div>
                            
                            <div className="col-12 mb-3">
                                <div className="d-flex justify-content-between p-1">
                                    <div>
                                        <input onClick={(e)=>{ SetRemember(e.target.checked)}} type="checkbox" className="form-check-input me-2" id="exampleCheck1"/>
                                        <label className="form-check-label">Simpan Login</label>
                                    </div>
                                    <div>
                                        <span onClick={()=> setModalForget({...modalForget,"show" : true})} style={{cursor:"pointer"}} className='text-warning'>Lupa Password ?</span>
                                    </div>
                                </div>
                            </div>

                            <div className="col-12 mb-4">
                                <button onClick={()=>{ postLogin()}} className="btn amr-btn w-100 p-2" style={{borderRadius: "15px"}}>
                                  Login
                                  {BtnLoading == true ? (
                                    <i className="ms-1 fa-solid fa-spinner amr-loader"></i>
                                  ) : (
                                    <></>
                                  )}
                                </button>
                            </div>

                            <div className="col-12 mb-3">
                                <div className="amr-hr amr-font-1">Belum Punya Akun ?</div>
                            </div>

                            <div className="col-12 mb-2">
                                <button onClick={()=>navigate('/register')} className="btn btn-success w-100 p-2" style={{borderRadius: "15px"}}>
                                  Daftar Sekarang
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
                
            </div>
        </div>
    </div>

    <Modal show={modalForget.show} onHide={() => setModalForget({...modalForget,"show" : false})}>
        <Modal.Body style={{"backgroundColor" : "rgb(39, 39, 48)"}}>
            <div className="text-end mb-3 position-absolute" style={{"right": "15px",zIndex : "1"}}>
                <span onClick={() => setModalForget({...modalForget,"show" : false})} type="button" data-bs-dismiss="modal" aria-label="Close">
                    <i className="fa-solid fa-xmark" style={{"fontSize": "22px"}}></i>
                </span>
            </div>
            <h5 className="text-center fw-bold amr-font-1">Reset Password</h5>
            <hr/>
            <ResetPassword modal={{data : modalForget,setData : setModalForget}}/>
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default Login