import { faLock, faShield, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react'
import { Nav,Tab} from 'react-bootstrap'
import { ArrowLeft } from 'react-feather';
import Skeleton from 'react-loading-skeleton';
import { useNavigate } from 'react-router-dom';
import { Func } from '../../glob/Func';
import Notify from '../../glob/Notify';
import { Akun, Password, Security } from './ProfileTabs';
import "./Style/Profile.css";
function ProfileEdit() {
  const [core,setCore] = useState({loading : true});
  const [User,setUser] = useState();
  const cekLogin = async() => {
    var cek = await Func.cekLogin();
    if(cek.status == true){
      setUser(cek.data)
      setCore({"loading" : false})
    } else {
      navigate("/login")
      Notify({
        "type" : "error",
        "pesan" : "Login tidak valid"
      })
    }
  }
  useEffect(() => {
    cekLogin()
  }, []);
  const navigate = useNavigate();
  return (
    <div id="profile-edit" className=''>
        {core.loading == true ? (
            <Skeleton height={200}></Skeleton>
        ) : (
            <>
            <div className='mb-3'>
                <span className='amr-font-1 h5'><ArrowLeft cursor={"pointer"} onClick={()=>navigate("/profile")} className='me-3'/> Pengaturan Akun</span>
            </div>

            <Tab.Container id="left-tabs-example" defaultActiveKey="profile">
            <div className='col-12'>
                <Nav variant="pills" className="">
                    <Nav.Item>
                        <Nav.Link eventKey="profile"><FontAwesomeIcon className='me-1' icon={faUser}/> <span className='hideOnMobile'>Profile</span></Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey="password"><FontAwesomeIcon className='me-1' icon={faLock}/><span className='hideOnMobile'> Password </span></Nav.Link>
                    </Nav.Item>
                    {/*}
                    <Nav.Item>
                        <Nav.Link eventKey="security"><FontAwesomeIcon className='me-1' icon={faShield}/> Kemanan</Nav.Link>
                    </Nav.Item>
                    {*/}
                </Nav>
            </div>
            <div className='col-12 mt-2'>
                <Tab.Content>
                    <Tab.Pane eventKey="profile">
                        <Akun user={{data: User,setData: setUser}}/>
                    </Tab.Pane>
                    <Tab.Pane eventKey="password">
                        <Password/>
                    </Tab.Pane>
                    <Tab.Pane eventKey="security">
                        <Security/>
                    </Tab.Pane>

                </Tab.Content>
            </div>
            </Tab.Container>
            </>
        )}
    </div>
  )
}

export default ProfileEdit